import PropTypes from "prop-types";
import React from "react";

import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main";

function LayoutIndex({ children }) {
    return (
        <div className="flex flex-col min-h-screen font-sans text-gray-900">
            <Header withHero={true}/>

            <Main>
                {children}
            </Main>

            <Footer lastSectionColor="dark"/>
        </div>
    );
}

LayoutIndex.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LayoutIndex;
