import React from "react";

import LayoutIndex from "../components/LayoutIndex";
import SEO from "../components/SEO";
import Section from "../components/Section";
import SVGCurve from "../components/SVGCurve";
import SectionRow from "../components/SectionRow";
import ImageDiv from "../components/ImageDiv";
import TextDiv from "../components/TextDiv";
import LinkButton from "../components/LinkButton";
import TextParagraph from "../components/TextParagraph";
import UnorderedList from "../components/UnorderedList";
import { StaticImage } from "gatsby-plugin-image";
import AppointmentDiv from "../components/AppointmentDiv";

function IndexPage() {

    return (
        <LayoutIndex>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Telepsychiatry`, `Psychiatry`, `Psychiatrist`, `Texas`,
                    `Counseling`, `Medication`, `Psychotherapy`, `Therapy`, `Counselor`]}
                title="Psychiatric Care & Counseling in Texas"
                description="Agile Psychiatry provides Online Psychiatric Care and Counseling in Texas with care
                provided by Brian Nahas, MD."
            />

            <Section shade="mid">
                <SectionRow>
                    <ImageDiv className="w-5/6 md:w-2/5 lg:w-1/3">
                        <StaticImage src="../images/card.profile.jpg" className="shadow-lg rounded-lg object-cover" alt="An image of Brian Nahas, MD" imgClassName="rounded-lg"/>
                    </ImageDiv>
                    <TextDiv className="md:w-3/5 lg:w-2/3">
                        <h2 className="text-3xl text-center">Brian Nahas, MD</h2>
                        <h4 className="text-xl pb-4 text-center italic">Board Certified Psychiatrist</h4>
                        <TextParagraph className="md:text-xl lg:text-2xl">
                            As the founder of Agile Psychiatry, Dr. Nahas oversees all aspects of clinical care. He relies
                            on a collaborative, whole-person approach to patient care, offering both therapy and medication management.
                        </TextParagraph>
                        <LinkButton to="/about" className="block mt-8 text-ap-action md:text-sm self-end">Learn More</LinkButton>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <ImageDiv className="w-5/6 md:w-2/5 lg:w-1/3">
                        <StaticImage src="../images/card.library.jpg" className="shadow-lg object-cover" alt="An image of a library" imgClassName="rounded-lg"/>
                    </ImageDiv>
                    <TextDiv className="md:w-3/5 lg:w-2/3">
                        <h2 className="text-3xl pb-4 text-center">Overview</h2>
                        <UnorderedList className="ml-6 md:ml-0 text-xl md:text-xl lg:text-2xl">
                            {/*<li>Accepting new patients ages 18 - 60</li>*/}
                            <li>Office serving patients in Austin / Cedar Park / Georgetown</li>
                            <li>Online appointments available from anywhere in Texas</li>
                            <li>Daytime and evening appointments</li>
                        </UnorderedList>
                        {/*<TextParagraph>*/}
                            {/*At Agile Psychiatry, our philosophy is to provide personalized care that focuses on the*/}
                            {/*whole person, taking into account both the body and mind. We utilize evidence-based psychotherapy,*/}
                            {/*medication management, and lifestyle counseling to address each individual&apos;s personal needs,*/}
                            {/*and we rely on the latest technology to deliver care and streamline communication.*/}
                        {/*</TextParagraph>*/}
                        <LinkButton to="/services" className="block mt-8 text-ap-action md:text-sm self-end">Learn More</LinkButton>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <ImageDiv className="w-5/6 md:w-2/5 lg:w-1/3">
                        <StaticImage src="../images/card.medication.jpg" className="shadow-lg object-cover" alt="An image of medicine bottles on a table" imgClassName="rounded-lg"/>
                    </ImageDiv>
                    <TextDiv className="md:w-3/5 lg:w-2/3">
                        <h2 className="text-3xl pb-4 text-center">Services and Fees</h2>
                        <UnorderedList className="ml-6 md:ml-0 text-xl md:text-xl lg:text-2xl">
                            <li>Medication Management / Psychotherapy</li>
                            <li>Treating a wide variety of symptoms and diagnoses</li>
                            <li>Pay with credit / debit / HSA card</li>
                            <li>Documentation for reimbursement from insurance plans</li>
                        </UnorderedList>
                        {/*<TextParagraph>*/}
                        {/*    Agile Psychiatry provides both online counseling and medication management*/}
                        {/*    for a wide variety of symptoms and diagnoses. Agile Psychiatry is a private pay*/}
                        {/*    clinic but can provide documentation for reimbursement from insurance plans. As an online-only clinic,*/}
                        {/*    there are some small differences in the treatment that is available.*/}
                        {/*</TextParagraph>*/}
                        <LinkButton to="/services" className="block mt-8 text-ap-action md:text-sm self-end">Learn More</LinkButton>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <AppointmentDiv/>
                    {/*<ImageDiv className="w-5/6 md:w-2/5 lg:w-1/3">*/}
                    {/*    <StaticImage src="../images/card.computer.jpg" className="shadow-lg rounded-lg object-cover" alt="An image of a computer"/>*/}
                    {/*</ImageDiv>*/}
                    {/*<TextDiv className="md:w-3/5 lg:w-2/3">*/}
                    {/*    <h2 className="text-3xl pb-4 text-center">Consulting</h2>*/}
                    {/*    <TextParagraph className="md:text-xl lg:text-2xl">*/}
                    {/*        Dr. Nahas is available for speaking or consulting engagements related to*/}
                    {/*        technology, healthcare, and mental health.*/}
                    {/*    </TextParagraph>*/}
                    {/*    <LinkButton to="/consulting" className="block mt-8 text-ap-action md:text-sm self-end">Learn More</LinkButton>*/}
                    {/*</TextDiv>*/}
                </SectionRow>
            </Section>
        </LayoutIndex>
    );
}

export default IndexPage;
